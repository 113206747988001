import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { Player } from "video-react"

import ReactPlayer from "react-player/lazy"

import { Link } from "gatsby"

// Countdown timer
import Countdown from "react-countdown"
import moment from "moment"

// Nightlife launch date
const event = moment()
  .isoWeekday("Friday")
  .hour(17)
  .minute(0)
const ISOevent = event.toISOString()

function VideoPlayer() {
  return (
    <Container>
      <VideoContainer>
        <iframe
          src="https://giphy.com/embed/6rYISL1oRODINCqxMq"
          style={{
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
          frameBorder="0"
          class="giphy-embed"
        ></iframe>
      </VideoContainer>

      <Content>
        <Nightlife>
          <Heading>Nightlife Begins</Heading>
          <Timer date={ISOevent} />
        </Nightlife>
        <StyledLink href="http://onelink.to/pgqs55">
          <Download>Download Now</Download>
        </StyledLink>
      </Content>
    </Container>
  )
}

export default VideoPlayer

const Container = styled.div`
  position: fixed;

  // size
  height: 100vh;
  width: 100vw;

  // resetting
  margin: 0;
  padding: 0;

  // resetting video-player margin
  * {
    margin: 0;
    padding: 0;
  }

  display: flex;
`

const StyledReactPlayer = styled(ReactPlayer)``

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 5;
`

const Nightlife = styled.div`
  // media query normal
  @media screen and (min-width: 768px) {
    // size
    width: 100%;
  }
`

const Heading = styled.h1`
  // resetting
  margin: 0;
  padding: 0;

  // typography
  font-size: 2.6rem;
  font-weight: bold;

  // alignment
  text-align: center;

  // color
  color: #fff;
  text-shadow: 3px -3px 0px rgba(0, 0, 0, 0.5);

  // media query
  @media screen and (min-width: 768px) {
  }

  // media query for larger headings
  @media screen and (min-width: 1400px) {
    // size
    font-size: 4rem;
  }
`

const Timer = styled(Countdown)`
  // margin & padding
  margin: 10px 0 40px 0;
  padding: 0;
  text-shadow: 1px -1px 0px rgba(0, 0, 0, 0.5);

  // typography
  font-size: 2.5rem;

  // alignment
  text-align: center;

  // color
  color: #fdde68;

  // display-options
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
  }
`
const Download = styled.div`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 60px;
  width: 100%;
  border-radius: 50px;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  // typography
  font-size: 1.5rem;
  font-weight: bold;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  -moz-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);

  @media screen and (min-width: 1600px) {
    // size
    height: 70px;
  }

  @media screen and (min-width: 1920px) {
    // size
  }
`

const StyledLink = styled(props => <a {...props} />)`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 60px;
  width: 60%;
  border-radius: 50px;
  max-width: 350px;

  // typography
  font-size: 1.5rem;

  // color
  color: #333;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 550px) {
    // size
    width: 40%;
  }

  @media screen and (min-width: 768px) {
    // size
    width: 60%;
  }

  @media screen and (min-width: 1600px) {
    // size
    width: 50%;
  }

  @media screen and (min-width: 1920px) {
    // size
    width: 40%;
  }

  @media screen and (min-width: 2800px) {
    // size
    width: 30%;
  }
`
